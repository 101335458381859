<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Название: </a-col>
            <a-col :span="18" class="sm-mb">
              <a-input
                v-model="form['information_' + getKey]"
                class="form_title-input"
                name="linkName"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>
          <a-row class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="document_files_array.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="document_files_array.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>

              <InputFileItem
                v-for="(doc, index) in newsDocs"
                :key="index"
                :href="form.file"
                :file="{ ...doc, name: doc.file.split('/').pop() }"
                class="mt-2"
                link
                @delete="deleteInputFile(doc)"
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4" class="form_title" style="padding-top: 2px">
              Изображение:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload Image
              </a-button>
              <span v-if="!image_url" style="padding-left: 8px">
                Картинка не выбрана
              </span>
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFileUpload"
              />
              <div class="committee-image">
                <img :src="image_url" />
              </div>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Позиция: </a-col>
            <a-col :span="4" class="sm-mb">
              <a-input
                v-model="form.position"
                type="number"
                name="position"
                placeholder="Позиция"
              />
              <!-- <small
                v-if="$v.form.link.$dirty && !$v.form.link.required"
                class="error-message"
                >Введите название ссылки</small
              > -->
              <!-- <small v-else-if="!$v.form.link.value" class="error-message"
                >Введите корректную ссылку в которой есть http:// или
                https://</small
              > -->
            </a-col>
          </a-row>
          <a-row style="margin-top: 15px">
            <a-col :span="4" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="4" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />Отмена
                </a-button>
                <a-button type="primary" @click="submitUsefullLinks">
                  <a-icon type="check" />Добавить
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import InputFileItem from "@/components/utils/input-file-item"
import cloneDeep from "lodash/cloneDeep"

export default {
  components: {
    // "image-upload": () => import("@/components/utils/image-upload")
    InputFileItem
  },
  data() {
    return {
      form: {
        information_uz: "",
        information_ru: "",
        information_en: "",
        information_oz: "",
        information_qr: "",
        is_active: false,
        category: null,
        position: "",
        type: 2,
        photo: null
      },
      langTab: "oz",
      image: null,
      image_url: "",
      document_files_array: []
    }
  },
  computed: {
    getKey() {
      return this.langTab
    },
    newsDocs() {
      return (
        this.newsFiles && this.newsFiles.filter((item) => item.file_type === 2)
      )
    }
  },
  created() {
    this.form.category = this.$route.params.cat_id
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    async submitUsefullLinks() {
      // if (this.$v.$invalid) {
      //   this.$v.$touch()
      //   return
      // }
      let newData = cloneDeep(this.form)
      newData = {
        ...newData
      }
      if (typeof newData.photo === "string") {
        delete newData.photo
      }
      let f = new FormData()

      Object.keys(newData).forEach((key) => {
        f.append(key, newData[key])
        // f === "photo" && f.append(key, this.form[key], this.form[key].name)
      })
      this.document_files_array.forEach((item) => {
        f.append("file", item.binary_file, item.binary_file.name)
      })
      try {
        let res = await this.$api.post("/admin/common/document/create/", f)
        console.log(res)
        if (res && res.status === 201) {
          this.$message.success("Полезная ссылка создана успешно")
          this.$router.push({ name: "reviews-docs" })
        } else {
          this.$message.error("Ошибка при добавлении")
        }
      } catch (e) {
        this.$message.error("Ошибка при добавлении")
        console.log(e)
      }
    },
    deleteDocumentFileFromState(e) {
      this.document_files_array = this.document_files_array.filter((file) => {
        return file.uid !== e.uid
      })
      // console.log(this.document_files_array, e)
    },
    handleImages(arg) {
      console.log(arg)
      this.form.photos = arg
    },

    handleFileUpload(e) {
      // this.form.photo = this.$refs.file.files[0]
      console.log(e.target.files[0])
      this.image_url = URL.createObjectURL(e.target.files[0])
      this.form.photo = e.target.files[0]
    },
    handleDocumentInput(e) {
      // console.log(e.target.files)
      this.file_List = e.target.files
      if (e.target.files && e.target.files !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this.document_files_array = [
            ...this.document_files_array,
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
        })
      }
      console.log("array", this.document_files_array)
      this.$refs.documentRef.value = null
    }
  },
  validations: {
    form: {
      value: {
        required
      }
    }
  }
}
</script>

<style>
/* .committee-image {
  overflow: hidden;
  margin-top: 15px;
} */
/* .committee-image > img {
  height: 200px;
} */
/* .isInvalidUrl {
  border: 1px solid red;
  box-shadow: 0 0 3px red;
} */
.error-message {
  color: red;
  font-size: 12px;
}
</style>
